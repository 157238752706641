import { useState, useContext } from 'react';
import { string, bool, func, oneOfType, object } from 'prop-types';
import { Modal, Button, Form, Input } from 'antd';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { DialogTrigger } from 'react-aria-components';
import crypto from 'crypto-js';

import { Button as ButtonLab } from 'components/ui/button';
import { Drawer as DrawerLab, DrawerRoot, DrawerX } from 'components/ui/drawer';
import { LabCloseIcon } from 'components/camperlab/icons/close';
import FormInput from 'components/formInput';
import { useUser, userContext } from 'context/user';
import { useI18n } from 'context/i18n';
import { PowerSVG } from 'components/iconsSVG/power';
import { CloseMdSVG } from 'components/iconsSVG/close';
import OldProtectionDataModal from 'components/protectionDataModal';
import { PrivacyPolicyText } from 'components/legalTexts';
import { recoverPassword } from 'actions/users';

import { handleStorage } from 'utils/helpers';
import { dataLayerHandleEvent } from 'utils/dataLayers';
import { IS_CAMPER, IS_NNORMAL } from 'utils/constants/system';
import useDelayedState from 'hooks/useDelayedState';
import { useLocale, useValidate } from 'hooks';

import styles from './style.module.css';

const { SHA256 } = crypto;

export const ForgotPasswordForm = ({ onClickCancel = () => {} }) => {
  const { t, locale } = useI18n();
  const validate = useValidate();
  const [loading, setLoading] = useState(false);
  const [requestSend, setRequestSend] = useState(false);
  const [error, setError] = useState(false);
  const [forgotPasswordForm] = Form.useForm();

  const handleRecover = async (data) => {
    setLoading(true);
    const result = await recoverPassword({ email: data.email, profile: locale });
    if (result.error) {
      setLoading(false);
      // sacar mensaje de error descriptivo?
      setError(t('mi.cuenta', 'usuario.incorrecto'));
    }
    setLoading(false);
    setRequestSend(true);
  };

  const onFinish = (values) => {
    handleRecover(values);
  };

  return (
    <>
      <p className={styles.myAccountTitle}>{t('mi.cuenta', 'recuperar.password.titulo', 'Retrieve your password')}</p>
      <p className={styles.accountDescription}>
        {t('mi.cuenta', 'recuperar.password.descripcion.1', 'Enter the email address you used to register on Camper.com')}
      </p>
      <p className={styles.accountDescription}>
        {t('mi.cuenta', 'recuperar.password.descripcion.2', 'We will send the link to access your account to the same email address.')}
      </p>
      <div className={styles.myAccountloginForm}>
        <Form
          form={forgotPasswordForm}
          layout="vertical"
          initialValues={{
            email: '',
          }}
          name="forgotPassword"
          onFinish={onFinish}
          requiredMark="optional"
          scrollToFirstError
          className="customForm"
        >
          <FormInput name="email" rules={validate('email')} floatLabel={t('mi.cuenta', 'mi.cuenta.email').replace(':', '')} required>
            <Input autoFocus={true} placeholder="" type="email" formNoValidate />
          </FormInput>

          {error && <p className={styles.errorText}>{error}</p>}
          <Form.Item>
            <Button loading={loading} className={styles.createAccountBtn} type="primary" block htmlType="submit">
              {t('mi.cuenta', 'recuperar.password', 'Retrieve your password')}
            </Button>
          </Form.Item>
          {requestSend && <p>{t('mi.cuenta', 'recuperar.password.peticion.enviada', 'The recovery email has been sent, please check your inbox.')}</p>}
          <p className={styles.cancelBtn} onClick={!loading ? onClickCancel : () => true}>
            {t('mi.cuenta', 'recuperar.password.volver', 'Volver')}
          </p>
        </Form>
      </div>
    </>
  );
};

ForgotPasswordForm.propTypes = {
  label: string,
  onClose: func,
  onClickCancel: func,
};

export const MyAccountLoginForm = ({ onClose = () => {}, onClickRegister = () => {}, onClickForgot = () => {} }) => {
  const { login } = useUser();
  const { t, locale } = useI18n();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [seePassword, setSeePassword] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    const data = formData;
    setLoading(true);
    const result = await login(data.email, data.password, locale);
    if (result.error) {
      setLoading(false);
      // sacar mensaje de error descriptivo?
      setError(t('mi.cuenta', 'usuario.incorrecto'));
    }
    if (result.info) {
      handleStorage('removeItem', 'shippingForm');

      handleStorage(
        'setItem',
        'shippingForm',
        JSON.stringify({
          ...result.info,
          name: result.info.username,
          telephone: result.info.telephone && result.info.telephone.length ? result.info.telephone.split(' ').splice(1).join('') : '',
        }),
      );

      dataLayerHandleEvent({
        event: 'mailShadowed',
        mailSHA256: SHA256(data.email?.toLowerCase()).toString(),
      });

      dataLayerHandleEvent({
        event: 'login',
        method: 'email',
      });

      if (onClose) {
        onClose();
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onClickEye = () => {
    setSeePassword(!seePassword);
  };
  const fontSizeClass = 'text-sm lg:text-xs';
  const inputClass = 'mb-4 !text-[16px] !leading-[18.4px] md:!text-[12px] md:!leading-[14.4px]';
  return (
    <form onSubmit={handleLogin} className="flex w-full flex-grow flex-col">
      <div className="flex-grow px-2.5">
        <div className="flex flex-shrink-0 flex-col">
          <p className={`mb-1 font-bold uppercase ${fontSizeClass}`}>{t('mi.cuenta', 'login.title')}</p>
          <p className={`mb-5 ${fontSizeClass}`}>{t('mi.cuenta', 'login.description')}</p>
          <div className={inputClass}>
            <label htmlFor="email" className="mb-1 block font-bold">
              {t('mi.cuenta', 'mi.cuenta.email')}
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="h-[30px] w-full border border-[#CCCCCC] px-1 focus:outline-none focus:ring-2 md:h-6"
              placeholder=""
            />
          </div>
          <div className={inputClass}>
            <label htmlFor="password" className="mb-1 block font-bold">
              {t('mi.cuenta', 'password')}
            </label>
            <div className="relative">
              <input
                type={!seePassword ? 'password' : 'text'}
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                className="h-[30px] w-full border border-[#CCCCCC] px-1 focus:outline-none focus:ring-2 md:h-6"
                placeholder=""
              />
              <img onClick={onClickEye} className="absolute right-1 top-1 cursor-pointer" src="/assets-new/svg/camperlab/eye.svg" alt="eye" />
            </div>
          </div>
          {error ?
            <p className="text-error">{error}</p>
          : null}
          <button type="button" className="mt-1 text-left !text-[12px] font-bold !leading-[14.4px] underline md:!text-[10px] md:!leading-[12px]">
            {t('mi.cuenta', 'forgot.your.password', 'Forgot your password?')}
          </button>
        </div>
      </div>
      <div className="sticky bottom-0 flex flex-shrink-0 flex-col gap-2.5 bg-clear p-2.5">
        <ButtonLab type="submit" isPending={loading} isDisabled={loading} className="h-8 w-full px-2 text-sm font-bold uppercase md:h-6">
          <span className="text-sm md:text-xs">{t('mi.cuenta', 'identificate')}</span>
        </ButtonLab>
        <ButtonLab
          type="button"
          isPending={loading}
          isDisabled={loading}
          onPress={onClickRegister}
          variant="outline"
          className="h-8 w-full px-2 text-sm font-bold uppercase md:h-6"
        >
          <span className="text-sm md:text-xs">{t('mi.cuenta', 'crear.cuenta')}</span>
        </ButtonLab>
      </div>
    </form>
  );
};

MyAccountLoginForm.propTypes = {
  label: string,
  onClose: func,
  onClickRegister: func,
  onClickForgot: func,
};

export const MyAccountRegisterForm = ({ onSuccess, onClickReturnLogin }) => {
  const { createAccount } = useUser();
  const { t, locale, profileData } = useI18n();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    passwordRepeat: '',
    privacy: false,
    newsletter: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [seePassword, setSeePassword] = useState(false);
  const [privacyPolicyModalVisible, setPrivacyPolicyModalVisibile] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const onClickEye = () => {
    setSeePassword(!seePassword);
  };

  const handleCreateAccount = async (e: React.FormEvent) => {
    e.preventDefault();
    const data = formData;
    let errorInCreate = false;
    setLoading(true);
    try {
      const recaptchaToken = await executeRecaptcha('create_account');
      const result = await createAccount(locale, {
        email: data.email,
        password: data.password,
        captchaToken: recaptchaToken,
        newsletter: data.newsletter === true,
      });
      if (result && result.error === false) {
        if (result.info) {
          handleStorage('removeItem', 'shippingForm');
          handleStorage(
            'setItem',
            'shippingForm',
            JSON.stringify({
              ...result.info,
              name: result.info.username,
              telephone: result.info.telephone && result.info.telephone.length ? result.info.telephone.split(' ').splice(1).join('') : '',
            }),
          );
        }

        dataLayerHandleEvent({
          event: 'mailShadowed',
          mailSHA256: SHA256(data.email?.toLowerCase()).toString(),
        });

        dataLayerHandleEvent({
          event: 'sign_up',
          method: 'email',
        });

        onSuccess();
      } else {
        errorInCreate = result.errorMessage;
      }
    } catch (e) {
      errorInCreate = e;
    }
    if (errorInCreate !== false) {
      console.error(errorInCreate);
      setError(typeof errorInCreate === 'string' ? errorInCreate : t('generico', 'error.500'));
    }
    setLoading(false);
  };
  const fontSizeClass = 'text-sm lg:text-xs';
  const inputClass = 'mb-4 !text-[16px] !leading-[18.4px] md:!text-[12px] md:!leading-[14.4px]';
  const inputCheckboxClass = 'flex items-baseline !text-[16px] !leading-[18.4px] md:!text-[12px] md:!leading-[14.4px]';
  return (
    <form onSubmit={handleCreateAccount} className="flex w-full flex-grow flex-col">
      <div className="flex-grow px-2.5">
        <div className="flex flex-shrink-0 flex-col">
          <p className={`mb-1 font-bold uppercase ${fontSizeClass}`}>{t('mi.cuenta', 'register.title')}</p>
          <p className={`mb-5 ${fontSizeClass}`}>{t('mi.cuenta', 'register.description')}</p>
          <div className={inputClass}>
            <label htmlFor="email" className="mb-1 block font-bold">
              {t('mi.cuenta', 'mi.cuenta.email')}
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="h-[30px] w-full border border-[#CCCCCC] px-1 focus:outline-none focus:ring-2 md:h-6"
              placeholder=""
            />
          </div>
          <div className={inputClass}>
            <label htmlFor="password" className="mb-1 block font-bold">
              {t('mi.cuenta', 'password')}
            </label>
            <div className="relative">
              <input
                type={!seePassword ? 'password' : 'text'}
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                className="h-[30px] w-full border border-[#CCCCCC] px-1 focus:outline-none focus:ring-2 md:h-6"
                placeholder=""
              />
              <img onClick={onClickEye} className="absolute right-1 top-1 cursor-pointer" src="/assets-new/svg/camperlab/eye.svg" alt="eye" />
            </div>
          </div>
          <div className={inputClass}>
            <label htmlFor="passwordRepeat" className="mb-1 block font-bold">
              {t('mi.cuenta', 'repite.la.contrasena')}
            </label>
            <div className="relative">
              <input
                type={!seePassword ? 'password' : 'text'}
                id="passwordRepeat"
                name="passwordRepeat"
                value={formData.passwordRepeat}
                onChange={handleChange}
                required
                pattern={formData.password}
                className="h-[30px] w-full border border-[#CCCCCC] px-1 focus:outline-none focus:ring-2 md:h-6"
                placeholder=""
              />
              <img onClick={onClickEye} className="absolute right-1 top-1 cursor-pointer" src="/assets-new/svg/camperlab/eye.svg" alt="eye" />
            </div>
          </div>
          <button type="button" className="mb-5 mt-1 text-left text-xs font-bold underline" onClick={() => setPrivacyPolicyModalVisibile(true)}>
            {t('generico', 'camper.newsletter.proteccion.datos')}
          </button>
          <OldProtectionDataModal visible={privacyPolicyModalVisible} onCancel={() => setPrivacyPolicyModalVisibile(false)} origin="registro.myaccount" />
          <div className={inputCheckboxClass}>
            <input
              type="checkbox"
              id="privacy"
              name="privacy"
              checked={formData.privacy}
              required
              onChange={handleChange}
              className="text-black border-black h-3 w-3 rounded-none border accent-copy md:h-[9px] md:w-[9px]"
            />
            <label htmlFor="privacy" className="ml-2 font-bold">
              {t('compra.formulario', 'acepto.las')}&nbsp;
              <a href={profileData.urlPrivacyPolicy} target="_blank" rel="noreferrer">
                {t('generico', 'privacy.conditions', 'privacy conditions')}
              </a>
            </label>
          </div>
          <div className={inputCheckboxClass}>
            <input
              type="checkbox"
              id="newsletter"
              name="newsletter"
              checked={formData.newsletter}
              onChange={handleChange}
              className="text-black border-black h-3 w-3 rounded-none border accent-copy md:h-[9px] md:w-[9px]"
            />
            <label htmlFor="newsletter" className="ml-2 font-bold">
              {t('compra.formulario', 'informacion.comercial')}
            </label>
          </div>
          {error ?
            <p className="text-error">{error}</p>
          : null}
        </div>
      </div>
      <div className="sticky bottom-0 flex flex-shrink-0 flex-col gap-2.5 bg-clear p-2.5">
        <ButtonLab type="submit" isPending={loading} isDisabled={loading} className="h-8 w-full px-2 font-bold uppercase md:h-6">
          <span className="text-sm md:text-xs">{t('mi.cuenta', 'crear.cuenta')}</span>
        </ButtonLab>
        {onClickReturnLogin !== undefined ?
          <ButtonLab
            type="button"
            isPending={loading}
            isDisabled={loading}
            onPress={onClickReturnLogin}
            variant="outline"
            className="h-8 w-full px-2 font-bold uppercase md:h-6"
          >
            <span className="text-sm md:text-xs">{t('mi.cuenta', 'volver.al.login').replace('&lt;', '')}</span>
          </ButtonLab>
        : null}
      </div>
    </form>
  );
};

MyAccountRegisterForm.propTypes = {
  label: string,
  onSuccess: func,
  onClickReturnLogin: func,
  initialValues: object,
};

function LoggedInLinks() {
  const { locale } = useLocale();
  const { userData, logout } = useUser();
  const { t } = useI18n();

  return (
    <div className={styles.menuDiv}>
      {userData?.account?.username || userData?.account?.email ?
        <p className={`${styles.menuP} ${styles.welcomeTitle}`}>
          {`${t('mi.cuenta', 'hola', 'Hello')}, `}
          <b>{userData?.account?.username || userData?.account?.email}</b>
        </p>
      : null}
      <p className={styles.menuLink}>
        <a href={`/${locale}/myaccount${IS_NNORMAL ? '/nnormal-world' : ''}`}>{t('mi.cuenta', 'mi.cuenta', 'My Account')}</a>
      </p>
      <p className={styles.menuLink}>
        <a href={`/${locale}/myaccount/myorders`}>{t('mi.cuenta', 'mi.cuenta.pedidos.y.devoluciones', 'My Orders & Returns')}</a>
      </p>
      {IS_CAMPER ?
        <p className={styles.menuLink}>
          <a href={`/${locale}/myaccount/wishlist`}>{t('mi.cuenta', 'wishlist')}</a>
        </p>
      : null}
      <p className={styles.menuLink}>
        <a href={`/${locale}/myaccount/myprofile`}>{t('mi.cuenta', 'mi.perfil', 'My Profile')}</a>
      </p>
      <p className={styles.logoutTrigger}>
        <span onClick={logout}>
          <PowerSVG />
        </span>
      </p>
    </div>
  );
}

export const MyAccountFormWrapper = ({ onClose, initialRetrievePassword = false }) => {
  const { locale } = useI18n();
  const [visibleLoginForm, setVisibleLoginForm] = useState(true);
  const [retrievePassword, setRetrievePassword] = useState(initialRetrievePassword);

  const handleRegisterSuccess = () => {
    setVisibleLoginForm(true);
    onClose();
  };

  return (
    <>
      {visibleLoginForm ?
        <>
          {!retrievePassword && (
            <MyAccountLoginForm onClickRegister={() => setVisibleLoginForm(false)} onClose={onClose} onClickForgot={() => setRetrievePassword(true)} />
          )}
          {retrievePassword && (
            <>
              <ForgotPasswordForm onClose={onClose} onClickCancel={() => setRetrievePassword(false)} />
            </>
          )}
        </>
      : <GoogleReCaptchaProvider
          reCaptchaKey={process.env.RECAPTCHA_KEY}
          language={locale}
          useRecaptchaNet
          scriptProps={{
            async: false,
            defer: false,
            appendTo: 'body',
            nonce: undefined,
          }}
        >
          <MyAccountRegisterForm onSuccess={handleRegisterSuccess} onClickReturnLogin={() => setVisibleLoginForm(!visibleLoginForm)} />
        </GoogleReCaptchaProvider>
      }
    </>
  );
};

MyAccountFormWrapper.propTypes = {
  label: string,
  onClose: func,
  initialRetrievePassword: bool,
};

export const MyAccountButton = ({ handleOnClick }) => {
  const { t } = useI18n();
  return (
    <ButtonLab onPress={handleOnClick} className="h-8 w-full px-2 font-bold uppercase md:h-6">
      <div className="!text-[14px] !leading-[20px] md:!text-[12px] md:!leading-[16px]">{t('mi.cuenta', 'identificate')}</div>
    </ButtonLab>
  );
};

MyAccountButton.propTypes = {
  handleOnClick: func,
};

export const MyAccountDrawer = ({ visible = false, onClose = () => true, initialRetrievePassword = false, targetUrl }) => {
  const { t } = useI18n();
  const { userData } = useUser();

  const handleClose = (result) => {
    /* if (targetUrl !== false) {
      const url = targetUrl || `${window.location.origin}/${locale}/myaccount${IS_NNORMAL ? '/nnormal-world' : ''}`;
      window.location.assign(url);
    }

    onClose(result); */
  };

  const handleOnClose = () => {
    onClose();
  };

  return (
    <DialogTrigger isOpen={visible} onOpenChange={(isOpen) => (!isOpen ? handleOnClose() : () => true)}>
      <DrawerRoot className="w-full">
        <DrawerLab>
          <div className="relative flex h-full max-h-[100dvh] flex-grow flex-col overflow-x-hidden">
            <div className="sticky top-0 mb-32 flex flex-shrink-0 items-center justify-between bg-clear p-2.5">
              <div className="flex items-center md:relative md:order-2">
                <DrawerX absolute={false} />
              </div>
              <span className="hidden text-sm font-bold uppercase md:order-1 md:block">{t('mi.cuenta', 'identificate')}</span>
            </div>
            {userData === null ?
              <MyAccountFormWrapper onClose={onClose} initialRetrievePassword={initialRetrievePassword} />
            : null}
          </div>
        </DrawerLab>
      </DrawerRoot>
    </DialogTrigger>
  );
};

MyAccountDrawer.propTypes = {
  visible: bool,
  origin: string,
  onClose: func,
  initialRetrievePassword: bool,
  targetUrl: oneOfType([string, bool]),
};

function MyAccountWidget({ onClickYourOrders, onClickSignIn }) {
  const { userData, loading } = useContext(userContext);
  const { t } = useI18n();
  const { language, country } = useLocale();
  const myOrdersDisabled = true; //TODO: a futuro si estará habilitado asi que basta con quitar esta condicion;

  const [visible, setVisible] = useDelayedState(false);
  const handleOnMouseEnter = () => {
    if (window.innerWidth > 768) {
      setVisible(true);
    }
  };
  const handleOnMouseLeave = () => {
    if (window.innerWidth > 768) {
      setVisible(false, 300);
    }
  };
  const handleOnClick = () => {
    if (window.innerWidth <= 768) {
      setVisible(true);
    }
  };
  const fontSizeClass = 'text-sm lg:text-xs';
  if (myOrdersDisabled) {
    return (
      <button onClick={onClickYourOrders} className="text-[16px] font-bold uppercase leading-[18.4px] md:text-[0.75rem]">
        {t('menu.superior', 'mis.pedidos')}
      </button>
    );
  }
  return (
    <div className="" onClick={handleOnClick} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
      <span className="text-[16px] font-bold uppercase leading-[18.4px] md:text-[0.75rem]">{t('mi.cuenta', 'mi.cuenta.camperlab', 'ACCOUNT')}</span>
      {visible && (
        <div className="accountMenu absolute right-0 top-0 z-[10000] block h-full w-full overflow-auto bg-clear pb-2 pl-2 pr-0 pt-4 md:right-2.5 md:top-[calc(100%+12px)] md:h-auto md:w-[369px] lg:w-[243px] xl:w-[307px] 2xl:w-[371px]">
          <ButtonLab variant="none" onPress={() => setVisible(false)} className="-mt-2.5 flex size-7 items-center justify-center md:hidden">
            <span className="sr-only">{'Close'}</span>
            <LabCloseIcon aria-hidden />
          </ButtonLab>
          <div>
            {userData !== null ?
              <div key="myaccountlogedin" className={`${styles.menuOverlay} ${styles.menuOverlayLogedIn}`}>
                {/* <LoggedInLinks /> */}
                LOGEADO
              </div>
            : <div key="myaccount" className="mt-32 bg-clear p-2.5 md:mt-0">
                <div className="text-left">
                  <p className={`${fontSizeClass} mb-5 font-bold uppercase`}>{t('mi.cuenta', 'mi.cuenta.camper')}</p>
                  <p className={`${fontSizeClass} mb-2 font-bold`}>{t('mi.cuenta', 'identificate.aprovecha.ventajas')}</p>
                  <MyAccountButton handleOnClick={onClickSignIn} />
                  <p className={`${fontSizeClass} mb-5 mt-20 font-bold uppercase md:mt-10`}>{t('mi.cuenta', 'mis.pedidos', 'Orders')}</p>
                  <p className={`${fontSizeClass} mb-2 font-bold`}>{t('mi.cuenta', country === 'JP' ? 'manage.an.order.no.exchange' : 'manage.an.order')}</p>
                  <ButtonLab variant="outline" className="h-8 w-full px-2 font-bold uppercase md:h-6" data-lang={language} onPress={onClickYourOrders}>
                    <div className={fontSizeClass}>{t('menu.superior', 'mis.pedidos')}</div>
                  </ButtonLab>
                </div>
              </div>
            }
          </div>
        </div>
      )}
    </div>
  );
}

MyAccountWidget.propTypes = {
  onClickSignIn: func,
  onClickYourOrders: func,
};

export default MyAccountWidget;
