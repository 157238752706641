const TWS_MAGAZINES = {
  ibiza: 'https://e.issuu.com/embed.html?d=tws15_ibiza_LANG_isuu&u=campercontent',
  menorca: 'https://e.issuu.com/embed.html?d=tws16_menorca_LANG_isuu&u=campercontent',
  krk: 'https://e.issuu.com/embed.html?d=tws14_krk_digital_LANG&u=campercontent&hideIsuuLogo=true',
  ydra: 'https://e.issuu.com/embed.html?d=tws13_ydra_LANG&u=campercontent&hideIssuuLogo=true',
  corsica: 'https://issuu.com/campercontent/docs/camper_tws12_LANG',
  kypros: 'https://issuu.com/campercontent/docs/tws_11_issuu_LANG',
  sicilia: 'https://issuu.com/campercontent/docs/tws10_issuu_LANG',
  mallorca: 'https://issuu.com/campercontent/docs/magazine_tws_18_LANG',
  kriti: 'https://issuu.com/campercontent/docs/tws17_kriti_LANG',
};

const DEFAULT_MAGAZINE = 'mallorca';

const TWS_MAGAZINE_LANGS = ['es', 'it', 'fr', 'en', 'de', 'mx', 'cl'];

export function getTWSMagazineUrl(magazine: unknown, lang: string) {
  let magazineToUse = DEFAULT_MAGAZINE;
  const languageToUse = TWS_MAGAZINE_LANGS.includes(lang) ? lang : 'en';

  if (typeof magazine === 'string' && TWS_MAGAZINES[magazine]) {
    magazineToUse = magazine;
  }

  return TWS_MAGAZINES[magazineToUse].replace('LANG', languageToUse);
}
