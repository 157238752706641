import { useContext } from 'react';
import Files from 'react-butterfiles';

import { number, string, func, array } from 'prop-types';

import { i18nContext } from 'context/i18n';

import styles from './style.module.css';

const FileUploader = ({
  sizeLimitMB = 20,
  message,
  minCount = 1,
  maxCount = 10,
  acceptedTypes = ['image/jpeg', 'image/jpg', 'image/jpeg', 'image/gif', 'image/tif', 'image/tiff', 'image/bmp', 'image/png'],
  files = [],
  setFiles,
  errors = [],
  setErrors,
  inputText = '',
  className = '',
  icon = null,
  handleSuccess = null,
}) => {
  const { t } = useContext(i18nContext);

  const errorTypes = {
    multipleMaxCountExceeded: t('validation', `upload.maximum.files.${maxCount}`, '***Maximum 5 files are expected.'),
    minCount:
      minCount === 1 ?
        t('validation', 'required.file', '***Please upload a file.')
      : t('validation', 'upload.minimum.files', '***At least 2 files are expected.'),
    maxCount: t('validation', `upload.maximum.files.${maxCount}`, '***Maximum 5 files are expected.'),
  };

  const onRemove = (index) => {
    const newFiles = [...files];
    let newErrors = [...errors];
    newFiles.splice(index, 1);
    setFiles?.(newFiles);
    if (newFiles.length <= maxCount) {
      newErrors = newErrors.filter((error) => error.type !== 'maxCount');
    }
    if (newFiles.length < minCount) {
      if (newErrors.filter((error) => error.type === 'minCount').length === 0) {
        newErrors.push({ type: 'minCount' });
      }
    }
    setErrors?.(newErrors);
  };
  const onSuccess = async (newFiles) => {
    const newErrors = [];
    if (files.length === 0) {
      setFiles?.(newFiles);
    } else {
      setFiles?.([...files, ...newFiles]);
    }
    if (files.length + newFiles.length > maxCount) {
      newErrors.push({ type: 'maxCount' });
    }
    if (files.length + newFiles.length < minCount) {
      newErrors.push({ type: 'minCount' });
    }
    setErrors?.(newErrors);

    if (handleSuccess && typeof handleSuccess === 'function') {
      await handleSuccess(newFiles);
    }
  };
  return (
    <Files
      multiple={maxCount > 1}
      maxSize={`${sizeLimitMB}mb`}
      multipleMaxSize={`${sizeLimitMB}mb`}
      multipleMaxCount={maxCount}
      accept={acceptedTypes}
      onSuccess={(newFiles) => onSuccess(newFiles)}
      onError={(newErrors) => setErrors?.(newErrors)}
    >
      {({ browseFiles }) => (
        <>
          {message && <div className={styles.uploadMessage} dangerouslySetInnerHTML={{ __html: message }}></div>}
          {/* <div {...getDropZoneProps({ className: 'myDropZone' })}/> Por si en un futuro quiere dropZone tenerlo de referencia */}
          <div onClick={browseFiles} className={className || styles.uploadBtn}>
            {icon ?? <img src="/assets-new/img/svg/upload.svg" alt="upload" width={16} height={16} />}
            <span>{inputText || t('contacto', 'seleccionar.archivo', 'Select file')}</span>
          </div>
          <ol className={styles.ol}>
            {files.map((file, i) => (
              <li key={file.name}>
                <div>
                  <img src="/assets-new/img/svg/clip.svg" alt="clip" width={16} height={16} />
                  <span>{file.name}</span>
                </div>
                <img onClick={() => onRemove(i)} className={styles.remove} src="/assets-new/img/svg/remove.svg" alt="clip" width={16} height={16} />
              </li>
            ))}
            {errors.map((error) => {
              console.log(error);
              if (error.file) {
                return (
                  <li key={error.file.name} className={styles.error}>
                    <span>
                      {error.file.name} - {error.type}
                    </span>
                  </li>
                );
              }
              if (error.type) {
                return (
                  <li key={error.type} className={styles.error}>
                    <span>{error.type in errorTypes ? errorTypes[error.type] : error.type}</span>
                  </li>
                );
              }
              return (
                <li key={error} className={styles.error}>
                  <span>{error}</span>
                </li>
              );
            })}
          </ol>
        </>
      )}
    </Files>
  );
};

FileUploader.propTypes = {
  sizeLimitMB: number,
  message: string,
  minCount: number,
  maxCount: number,
  acceptedTypes: array,
  files: array,
  setFiles: func,
  errors: array,
  setErrors: func,
};

export default FileUploader;
