import { type ReactNode } from 'react';
import { Modal } from 'antd';

import { useI18n } from 'context/i18n';
import { CloseModalSVG } from 'components/iconsSVG/close';
import styles from './style.module.css';
import { Dialog, DialogRoot, DialogX } from 'components/ui/dialog';
import { DialogTrigger } from 'react-aria-components';
import { Button } from 'components/ui/button';
import { getDomainValue } from 'utils/domain';

export function OldProtectionDataModal({ visible, onCancel, origin = 'checkout' }: { visible: boolean; onCancel: () => void; origin: string }) {
  const { t, profileData } = useI18n();

  return (
    // @ts-ignore !antd
    <Modal width={'400px'} visible={visible} footer={null} onCancel={onCancel} closeIcon={<CloseModalSVG />} className={styles.protectionData}>
      <h2>{t('menu.derecha', 'informacion.proteccion.datos')}</h2>
      <div
        dangerouslySetInnerHTML={{
          __html: t('menu.derecha', `proteccion.datos.info.${origin}`).replace('<TAG privacy_policy_url>', profileData?.urlPrivacyPolicy),
        }}
      />
    </Modal>
  );
}

export function ProtectionDataModal({ trigger, origin = 'checkout' }: { trigger?: ReactNode; origin: string }) {
  const { t, profileData } = useI18n();

  return (
    <DialogTrigger>
      {trigger ?? (
        <Button variant="none" className="text-xs [&>span]:underline">
          {t(
            'generico',
            getDomainValue({
              camper: 'camper.newsletter.proteccion.datos',
              nnormal: 'newsletter.proteccion.datos',
              camperlab: 'camper.newsletter.proteccion.datos',
            }),
            'Data protection information',
          )}
        </Button>
      )}
      <DialogRoot isDismissable>
        <Dialog>
          <div>
            {t('menu.derecha', 'informacion.proteccion.datos')}
            <DialogX />
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: t('menu.derecha', `proteccion.datos.info.${origin}`).replace('<TAG privacy_policy_url>', profileData?.urlPrivacyPolicy),
            }}
          />
        </Dialog>
      </DialogRoot>
    </DialogTrigger>
  );
}

export default OldProtectionDataModal;
