export function PersonalShopperSVG(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <g clipPath="url(#a)">
        <mask id="b" fill="#fff">
          <path d="M19.985 10V5.28A5.265 5.265 0 0 0 14.721.014H5.28A5.265 5.265 0 0 0 .015 5.279v9.442a5.265 5.265 0 0 0 5.265 5.264H10" />
        </mask>
        <path
          fill="#1D1D1B"
          d="M20.985 10V5.28h-2V10h2Zm0-4.72a6.265 6.265 0 0 0-6.264-6.265v2a4.265 4.265 0 0 1 4.264 4.264h2ZM14.721-.986H5.28v2h9.44v-2Zm-9.441 0A6.265 6.265 0 0 0-.985 5.279h2A4.265 4.265 0 0 1 5.28 1.015v-2ZM-.985 5.279v9.442h2V5.279h-2Zm0 9.442a6.265 6.265 0 0 0 6.265 6.264v-2a4.265 4.265 0 0 1-4.265-4.264h-2Zm6.265 6.264H10v-2H5.28v2Z"
          mask="url(#b)"
        />
        <path
          stroke="#000"
          strokeMiterlimit={10}
          d="M19.485 17.466a2.019 2.019 0 1 1-4.037 0 2.019 2.019 0 0 1 4.037 0ZM13.27 10a3.27 3.27 0 1 1-6.54 0 3.27 3.27 0 0 1 6.54 0Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SimilarPairSVG(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={71} height={71} fill="none" {...props}>
      <path
        stroke="#919191"
        strokeWidth={1.5}
        d="m32.879 3.67-5.47.475-6.961-2.927-3.016 1.302-3.107 7.878 1.783 3.542s13.38 10.864 25.961 15.1l5.454.16s4.574-.593 8.16-4.457l.276-2.345-5.475-2.93"
      />
      <path stroke="#919191" strokeWidth={1.5} d="m42.07 29.04 2.605-5.775 5.81-3.797" />
      <path
        stroke="#919191"
        strokeWidth={1.5}
        d="m25.546 9.576 1.916-1.66 2.455.07 14.384 13.097.374 2.182M17.432 2.52l1.124 8.001 3.209 2.333 3.78-3.278M33.308 4.083l-.573 1.906 13.977 12.455 3.772 1.024"
      />
      <path
        stroke="#919191"
        strokeWidth={1.5}
        d="m33.21 3.314-6.547 4.348-.166 1.09M34.435 6.039 29.193 9.45M36.66 8.09l-5.243 3.413M38.883 10.143l-5.242 3.412M41.107 12.195l-5.243 3.412M43.479 14.252l-5.243 3.411M45.703 16.304l-5.243 3.411M14.325 10.398l-.527-.015-1.231 4.203 1.563 2.516S31.95 30.386 40.608 32.746l6.846.386s8.225-1.037 10.116-5.974l-.993-3.547-.618-1.213M25.546 9.576l17.973 16.25M50.484 19.468 32.88 3.67M37.035 36.099l-4.927 2.42-7.55-.213-2.34 2.304-.05 8.469 2.943 2.658s16.402 5.293 29.664 4.695l5.143-1.823s4.05-2.206 5.997-7.105l-.59-2.286-6.164-.753"
      />
      <path
        stroke="#919191"
        strokeWidth={1.5}
        d="m54.775 56.432.342-6.327 4.044-5.64M32.333 44.256l1.186-2.24 2.315-.822 18.145 7.012 1.138 1.899M22.217 40.61l3.94 7.054 3.835 1.015 2.34-4.423M37.585 36.33l.154 1.984 17.535 6.56 3.887-.41"
      />
      <path
        stroke="#919191"
        strokeWidth={1.5}
        d="m37.215 35.648-4.532 6.42.239 1.076M39.343 37.745l-3.655 5.076M42.158 38.854l-3.655 5.077M44.974 39.964l-3.655 5.076M47.789 41.073l-3.655 5.077M50.744 42.133l-3.655 5.076M53.559 43.243l-3.655 5.076M22.168 49.079l-.497.176.371 4.364 2.367 1.78s21.416 5.945 30.343 5.016l6.522-2.115s7.295-3.94 7.273-9.226l-2.208-2.948-1.015-.908M32.333 44.256l22.632 8.655M59.16 44.465l-22.125-8.367M3 24.153l7.5-4.5 5.5 4.5-5.5 5.5 2 8 6-2-1.5 12.5-15-6 4-2.5-4-9 1-6.5Z"
      />
    </svg>
  );
}

export function CompleteLookSVG(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={59} height={75} fill="none" {...props}>
      <path
        stroke="#919191"
        strokeWidth={1.5}
        d="m7.995 66.02 1.278-5.225-2.456-6.366.63-6.824 1.768-7.227.787-8.682-.177-7.78 2.436-1.452 3.595 2.928L9.27 52.299l1.333 3.057 27.071 6.625 2.524-2.113.648-2.648-1.192-.292-1-1.685.63-2.577 3.647-1.534m0 0 2.331-.416 3.086-1.976.753-3.077-2.087-2.103-2.893.278-2.261 1.04-2.209 1.734-.473 1.932 3.753 2.588ZM42.024 41.953l1.812 1.96 5.463-2.078 1.083-1.405.49-2.005-2.823-2.057-4.292.923-1.467 1.16-.386 1.575.12 1.926Z"
      />
      <path
        stroke="#919191"
        strokeWidth={1.5}
        d="m45.937 37.566 6.508-26.592-1.883-2.294-25.218-6.171-4.706 1.75-5.326 21.765M40.986 57.797l2.54-.203 4.094-2.37-.24-2.907-1.572-1.06M24.225 59.699l.99 2.965-.758 3.095M31.56 71.786l2.256-3.09 2.87-.996 2.817-3.842-.23-3.05"
      />
      <path
        stroke="#919191"
        strokeWidth={1.5}
        d="m39.35 53.966-1.068-.262m0 0-23.019-5.633-.75-1.255L23.54 9.923l1.227-.694 22.021 5.389.768 1.183-9.275 37.903ZM25.106 52.41l-1.03.891-.014 1.712 1.196.65.98-.689.265-1.079-.9-1.578-.497.093Z"
      />
      <path
        stroke="#919191"
        strokeWidth={1.5}
        d="m17.502 48.62 3.987-16.294 2.786-1.708 2.863.7 1.943-.989-.787-3.739.374-1.527-1.34-1.176.392-1.6.178-.728 1.046.564 1.166-2.952 2.015-1.28 5.166 1.264 1.546 2.151-.855 3.492 1.886.461-.392 1.6-2.08.34L35.7 31.1l-1.452.492.115 1.647 3.35.82 1.579 2.622-1.584 6.474-2.403 9.82M20.739 49.411l3.564-14.566M31.259 51.986l3.564-14.566"
      />
      <path stroke="#919191" strokeWidth={1.5} d="m30.973 25.113.56 1.424 1.28.956 1.862-.187 1.302-1.611" />
      <circle cx={32.525} cy={22.277} r={0.833} fill="#919191" transform="rotate(13.75 32.525 22.277)" />
      <circle cx={34.953} cy={22.871} r={0.833} fill="#919191" transform="rotate(13.75 34.953 22.871)" />
    </svg>
  );
}

export function PersonalShopperUploaderIconSVG(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
      <path
        stroke="#000"
        strokeLinejoin="round"
        d="M35 25v6.667A3.333 3.333 0 0 1 31.667 35H8.333A3.333 3.333 0 0 1 5 31.667V25M28.333 13.333 20 5l-8.333 8.333M20 5v20"
      />
    </svg>
  );
}
