/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { Modal, Spin } from 'antd';

import { useIsMobile } from 'hooks';
import { completeLookUnbxd, saveImageForSearch } from 'actions/search';

import { Button } from 'components/ui/button';

import { PersonalShopperSVG, SimilarPairSVG, CompleteLookSVG, PersonalShopperUploaderIconSVG } from 'components/iconsSVG/personalShopper';

import { useI18n } from 'context/i18n';

import FileUploader from 'components/fileUploader';
import { Tooltip } from 'components/ui/tooltip';
import { fileToBase64, handleStorage } from 'utils/helpers';

import { dataLayerHandleEvent } from 'utils/dataLayers';

import styles from './style.module.css';

const ShopperOption = ({ icon, text, onClick }) => {
  return (
    <div
      className="mt-[19px] flex cursor-pointer items-center rounded-[8px] border-[1px] border-solid border-[#919191] px-[15px] py-[10px] md:m-4 md:mt-8"
      onClick={onClick}
    >
      <div className="mr-2 min-w-[68px]">{icon}</div>
      <p className="m-0">{text}</p>
    </div>
  );
};

const PersonalShopper = ({}) => {
  const { t, locale } = useI18n();
  const { query } = useRouter();

  const isMobile = useIsMobile();

  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [mode, setMode] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileErrors, setFilesErrors] = useState([]);
  const [error, setError] = useState(null);

  const handleClickPersonalShopperButton = () => {
    dataLayerHandleEvent({
      event: 'main_menu',
      event_category: 'menu',
      event_detail_1: 'find similar and complete the look',
      event_detail_2: '',
    });
    setModalVisible((current) => !current);
  };

  const handleFindSimilarPair = async (file) => {
    dataLayerHandleEvent({
      event: 'upload_photo',
      event_category: 'find similar and complete the look',
      event_detail_1: 'find similar',
      event_detail_2: '',
    });
    setLoading(true);

    const sourceFile = file[0].src.file;
    const base64Image = await fileToBase64(sourceFile);

    const searchID = await saveImageForSearch({ profile: locale, encodedImage: base64Image });

    if (searchID) {
      window.location.assign(`${window.location.origin}/${locale}/search?mode=findSimilar&serp=new&target=A&imageID=${searchID}`);
    } else {
      setLoading(false);
      setError(true);
    }
  };

  const handleCompleteLook = async (file) => {
    dataLayerHandleEvent({
      event: 'upload_photo',
      event_category: 'find similar and complete the look',
      event_detail_1: 'complete the look',
      event_detail_2: '',
    });
    setLoading(true);
    setError(null);
    const { valid, query, errors } = await completeLookUnbxd({
      profile: locale,
      image: file[0].src.file,
    });

    if (valid === false) {
      setLoading(false);
      setError(t('generico', 'complete.look.error'));
      setFiles([]);
    } else {
      window.location.assign(`${window.location.origin}/${locale}/search?searchTerm=${query}&mode=completeLook&serp=new&target=A`);
    }
  };

  const handleClickDefaultScreen = (variant: string) => {
    const eventName = variant === 'similar' ? 'find_similar' : 'complete_the_look';

    dataLayerHandleEvent({
      event: eventName,
      event_category: 'find similar and complete the look',
      event_detail_1: '',
      event_detail_2: '',
    });
    setMode(variant);
  };

  const DefaultScreen = () => (
    <>
      <p className="mb-0 md:w-[80%] md:justify-self-center md:text-center">{t('generico', 'personal.shopper.description')}</p>

      <div className="flex flex-col text-[#919191] md:mt-9 md:flex-row md:justify-center">
        <ShopperOption icon={<SimilarPairSVG />} text={t('generico', 'find.similar.pair')} onClick={() => handleClickDefaultScreen('similar')} />
        <ShopperOption icon={<CompleteLookSVG />} text={t('generico', 'complete.your.look')} onClick={() => handleClickDefaultScreen('complete')} />
      </div>
    </>
  );

  const UploadPhotoScreen = ({ name, description, callback }) => (
    <form name={name} encType="multipart/form-data">
      <p className="md:text-center">{description}</p>
      <FileUploader
        minCount={1}
        maxCount={1}
        files={files}
        setFiles={setFiles}
        errors={fileErrors}
        setErrors={setFilesErrors}
        sizeLimitMB={50}
        inputText={t('generico', 'upload.photo')}
        className="mt-[5rem] flex cursor-pointer items-center justify-around bg-[#F6F6F6] p-[1rem]"
        icon={<PersonalShopperUploaderIconSVG />}
        handleSuccess={callback}
        key={name}
      />
      {error && <p className="error">{error}</p>}
    </form>
  );

  const screenMap = {
    similar: <UploadPhotoScreen name="findSimilar" description={t('generico', 'find.similar.pair.description')} callback={handleFindSimilarPair} />,
    complete: <UploadPhotoScreen name="completeLook" description={t('generico', 'complete.look.description')} callback={handleCompleteLook} />,
  };

  return (
    <div
      className={`absolute left-[80px] top-[16px] z-[1000] mobileXL:ml-[3%] mobileXXL:ml-[4%] sm:ml-[5%] pattersonMD:ml-[16%] ${styles.personalShopperWrapper}`}
    >
      <Tooltip placement="bottom" message={<span>{t('generico', 'shoogle.tooltip')}</span>}>
        <Button variant="none" onPress={handleClickPersonalShopperButton}>
          <PersonalShopperSVG />
        </Button>
      </Tooltip>
      {/* @ts-ignore antd error */}
      <Modal
        title={null}
        visible={modalVisible}
        footer={null}
        onCancel={() => {
          setModalVisible(false);
          setMode(null);
        }}
        className={styles.personalShopperModal}
        closable={true}
        destroyOnClose={false}
        zIndex={1000}
        width={isMobile ? '310px' : '800px'}
        // bodyStyle={popupProps?.style}
      >
        <div className="md:flex md:flex-col md:items-center md:justify-center">
          <p className="mt-4 text-2xl">{t('generico', 'shoogle.it')}</p>
          {/* @ts-ignore antd error */}
          <Spin spinning={loading}>{mode in screenMap ? screenMap[mode] : <DefaultScreen />}</Spin>
        </div>
        <div className="absolute bottom-4 flex flex-col">
          {mode !== null ?
            <span>{t('generico', 'image.upload.message')}</span>
          : null}
          <span>{t('generico', 'powered.by.ai')}</span>
        </div>
      </Modal>
    </div>
  );
};

export default PersonalShopper;
