export const AccountIconSVG = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={19} viewBox="0 0 25 27" className="header-account__ico">
    <path
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeWidth={1.2}
      {...props}
      d="M13.148 23.279h12.148l-1.536-3.24-7.603-2.877v-2.513s1.528-1.269 1.985-2.945l1.03-.897.422-2.39s-.642-.454-.66-.473c0 0 0-6.944-5.786-6.944S7.362 7.944 7.362 7.944c-.018.019-.66.473-.66.473l.422 2.39 1.03.897c.457 1.676 1.985 2.945 1.985 2.945v2.513L2.536 20.04 1 23.279h12.148Z"
    />
  </svg>
);
