import { useState, useEffect, useContext, useRef, useCallback, useMemo } from 'react';
import { AutoComplete } from 'antd';
import { autosuggestUnbxd } from 'actions/search';

import { dataLayerHandleEvent } from 'utils/dataLayers';
import { updateQuerystringParam, getLang, getMarket, getRealLanguage, getCookie, COOKIES, getIsMobile, getUnbxdTranslations } from 'utils/helpers';

import { i18nContext } from 'context/i18n';
import { useIsMounted } from 'hooks';

import PersonalShopper from './personalShopper';
import AutoSuggest from './autoSuggest';
import styles from './style.module.css';

const minSearchLength = 1;
let timeoutHandler = null;
let blurElementTimeout = null;

function SearchTNM() {
  const { t, locale, setSearchQuery, searchQueryShadow, setSearchQueryShadow, profileData, unbxdSearchResults, setUnbxdSearchResults } =
    useContext(i18nContext);
  const [value, setValue] = useState('');
  const [expandedSearch, setExpandedSearch] = useState(false);
  const [autosuggestVisible, setAutosuggestVisible] = useState(false);
  const isMounted = useIsMounted();
  const inputRef = useRef(null);
  const searchRef = useRef(null);
  const isMobile = getIsMobile('767.5px');

  const unbxdTranslations = useMemo(() => getUnbxdTranslations(t), [t]);
  const newSearch = profileData?.config?.unbxd === true && unbxdTranslations !== false;
  const hasPersonalShoppper = profileData?.config?.image_search === true;

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const searchValue = params.searchTerm;
    const searchMode = params.mode;

    if (searchValue && searchValue !== '' && searchMode !== 'completeLook') {
      setValue(searchValue);
    }
    if (process.browser) {
      window.triggerSearch = (searchStr) => {
        if (!isMounted()) return;

        if (!window.location.pathname.includes('/search')) {
          window.location.assign(`${window.location.origin}/${locale}/search?searchTerm=${searchStr}`);
        } else {
          updateQuerystringParam(window.location.search, 'searchTerm', searchStr);
          setSearchQuery(searchStr);
          setValue(searchStr);
          document.querySelector('#unbxd-search-box').value = searchStr;
        }
      };

      window.hideAutosuggest = () => {
        setAutosuggestVisible(false);
      };
    }
  }, []);

  const checkIsOutsideSearch = (e) => {
    const isOutsideSearch = e.target.closest('#search-test-b') === null;

    if (isOutsideSearch) {
      setAutosuggestVisible(false);
    }
  };

  const checkAutosuggestInViewport = () => {
    const autoSuggestWidget = document.getElementById('autosuggest-widget');

    if (autoSuggestWidget) {
      const dimensions = autoSuggestWidget.getBoundingClientRect();
      const { top, bottom } = dimensions;

      const isVisible = top >= 0 && bottom <= (window.innerHeight || document.documentElement.clientHeight);

      if (!isVisible) {
        setAutosuggestVisible(false);
      }
    }
  };

  useEffect(() => {
    if (autosuggestVisible === true) {
      document.addEventListener('mousedown', checkIsOutsideSearch);
      document.addEventListener('scroll', checkAutosuggestInViewport);
      // document.body.style.overflow = 'hidden';
    } else {
      document.removeEventListener('mousedown', checkIsOutsideSearch);
      document.removeEventListener('scroll', checkAutosuggestInViewport);
      // document.body.style.overflow = 'inherit';
    }
  }, [autosuggestVisible]);

  const onSearch = () => {};

  const blurActiveElement = () => {
    document.activeElement.blur();
  };

  const letsSearch = useCallback(
    async (locale, searchStr) => {
      if (!searchStr || searchStr.trim().length < minSearchLength) {
        return;
      }
      setSearchQueryShadow(searchStr);

      const lang = getLang(locale);
      const isoLanguage = getRealLanguage(lang);
      const unbxdUid = getCookie([COOKIES.UNBXD_UID]) || null;
      const results = await autosuggestUnbxd(isoLanguage, getMarket(locale), searchStr, unbxdTranslations, unbxdUid);
      // llamamos al API de unbxd de autosuggest
      // obtenemos respuesta y mostramos modal autosuggest
      // da CORS, usamos respuesta mockup

      setUnbxdSearchResults(results);

      if (isMobile) {
        clearTimeout(blurElementTimeout);
        blurElementTimeout = setTimeout(blurActiveElement, 1000);
      }

      dataLayerHandleEvent({
        event: 'virtualPage',
        pageName: window.location.pathname + window.location.search,
      });
    },
    [setSearchQueryShadow, setUnbxdSearchResults, unbxdTranslations],
  );

  const handleKeyUp = async (event) => {
    const searchStr = event.target.value;

    if (event.keyCode === 13) {
      if (!isMounted()) return;

      event.target?.blur();
      if (!window.location.pathname.includes('/search')) {
        window.location.assign(`${window.location.origin}/${locale}/search?searchTerm=${searchStr}`);
      } else {
        setAutosuggestVisible(false);
        updateQuerystringParam(window.location.search, 'searchTerm', searchStr);
        setSearchQuery(searchStr);
      }
    } else {
      clearTimeout(timeoutHandler);
      timeoutHandler = setTimeout(() => {
        letsSearch(locale, searchStr);
      }, 500);
    }
  };

  const onChange = (data) => {
    setValue(data);
  };

  const onSelect = (data) => {
    window.location.assign(`${window.location.origin}/${locale}/search?searchTerm=${data}`);
  };

  const onClickExpandIcon = () => {
    const tnmMobile = document.querySelector('.tnm--mobile');
    const filterNav = document.querySelector('#plp-filter-nav');
    const searchInput = document.querySelector('.tnm--mobile [class*="searchInput"]:not(.tnm__modal *)');
    const tnmModal = document.querySelector('.tnm__modal');
    let forcedCloseTnm = false;
    if (tnmModal && tnmModal.style.display !== 'none') {
      forcedCloseTnm = true;
      document.querySelector('.tnm__modal .tnm__close').click();
    }
    if (tnmMobile) {
      if (tnmMobile.style.height === '54px' || tnmMobile.style.height === '' || forcedCloseTnm) {
        tnmMobile.style.height = '100px';
        setExpandedSearch(true);
        if (inputRef?.current) {
          if (searchInput && searchInput.style) {
            searchInput.style.display = 'inline-block';
            setAutosuggestVisible(true);
            if (forcedCloseTnm) {
              document.querySelector('.tnm--mobile [class*="searchInput"]:not(.tnm__modal *)').style.top = '0px';
              inputRef.current.focus();
            }
          }
          inputRef.current.focus();
        }
      } else {
        tnmMobile.style.height = '54px';
        setExpandedSearch(false);

        if (searchInput && searchInput.style) {
          searchInput.style.display = 'none';
          setAutosuggestVisible(false);
        }

        if (filterNav?.style?.top === '100px') {
          filterNav.style.top = '50px';
        }
      }
    }
  };

  const onClickSearchIcon = () => {
    if (!window.location.pathname.includes('/search')) {
      window.location.assign(`${window.location.origin}/${locale}/search?searchTerm=${searchQueryShadow}`);
    } else {
      updateQuerystringParam(window.location.search, 'searchTerm', searchQueryShadow);
      setValue(searchQueryShadow);
      setSearchQuery(searchQueryShadow);

      if (typeof window?.webComponents?.closeTnm === 'function') {
        window.webComponents.closeTnm();
      } else {
        window.location.reload();
      }
    }
  };
  const onFocus = () => {
    const currentValue = document.activeElement.value;

    if (currentValue !== value) {
      setValue(currentValue);
    }
  };

  useEffect(() => {
    if (!['', null].includes(value) && searchRef.current !== null) {
      // hacemos esto porque el searchtnm está 3 veces en la web por cosas del tnm
      if (typeof searchRef.current?.checkVisibility === 'function') {
        const isVisible = searchRef.current?.checkVisibility();

        if (isVisible) {
          letsSearch(locale, value);
        }
      }
    }
  }, [locale, searchRef, letsSearch, value]);

  return (
    <div className={styles.wrapper}>
      {newSearch ?
        <>
          <div
            onFocus={() => {
              setAutosuggestVisible(true);
            }}
            id="search-test-b"
            ref={searchRef}
            className={`${styles.container} ${expandedSearch ? styles.expanded : styles.hidden}`}
          >
            <img className={styles.searchIcon} src="/assets-new/search.svg" width={30} height={30} alt="Search" onClick={onClickExpandIcon} />
            <AutoComplete
              id="unbxd-search-box"
              ref={inputRef}
              value={value}
              onClick={() => setAutosuggestVisible(true)}
              onSearch={onSearch}
              onKeyUp={handleKeyUp}
              onChange={onChange}
              onSelect={onSelect}
              placeholder={t('generico', 'input.placeholder', 'Search')}
              className={styles.searchInput}
              virtual={false}
              dropdownClassName={styles.searchDropdown}
              allowClear={false}
              backfill
              notFoundContent={null}
              showArrow
              suffixIcon={<img className={styles.suffixIcon} src="/assets-new/search.svg" width={17} height={17} alt="Search" onClick={onClickSearchIcon} />}
              onFocus={onFocus}
            ></AutoComplete>
            <AutoSuggest query={value} data={unbxdSearchResults} visible={autosuggestVisible} setVisible={setAutosuggestVisible} />
          </div>
          {hasPersonalShoppper ?
            <PersonalShopper />
          : null}
        </>
      : <div id="search-test-a" className={`${styles.searchV1}`}>
          <a href={`/${locale}/search`}>
            <input className={styles.inputV1} type="text" placeholder={t('generico', 'input.placeholder', 'Search')} />
            <img className={styles.searchIconV1} src="/assets-new/search.svg" width={17} height={17} alt="Search" />
          </a>
        </div>
      }
    </div>
  );
}

SearchTNM.propTypes = {};

export default SearchTNM;
